import Logo from './Logo.svg'
import Main from './Main.png'
import MenuOpen from './menu-icon.svg'
import MenuClose from './close.png'
import Play from './play.svg'
import Facebook from './facebook.svg'
import Instagram from './instagram.svg'
import Twitter from './twitter.svg'
import Linkedin from './linkedin.svg'
import Wikipedia from './wikipedia.svg'
import Mouse from './mouse.svg'
import Map from './map.svg'
import Brain from './Mask Group 1.png'
import Up from './up.svg'
import Down from './down.svg'
import Hex from './bg-hex.png'
import Connection from './connection.svg'
import Left from './left.svg'
import Right from './right.svg'
import Line from './line.svg'
import Last from './last.svg'
import Slash from './slash.svg'
import Next from './next.svg'
import ElvinAbbasov from './Elvin_Abbasov.jpg'
import Union from './logo-union.svg'
import Case from './case.svg'
import Dot from './dot.svg'
import PlayVideo from './play.png'
import BackArrow from './back-track.svg'
import BackLogo from './back-logo.png'
import NotFound from './not-found.svg'
import VirtualAzerbaijan from './Virtual.svg'


export default  {
    Logo,
    Main,
    NotFound,
    MenuOpen,
    Play,
    Facebook,
    Instagram,
    Twitter,
    Linkedin,
    Wikipedia,
    MenuClose,
    Mouse,
    Map,
    Brain,
    Up,
    Down,
    Hex,
    Connection,
    Left,
    Right,
    Line,
    Last,
    Slash,
    Next,
    ElvinAbbasov,
    Union,
    Case,
    Dot,
    PlayVideo,
    BackArrow,
    BackLogo,
    VirtualAzerbaijan,
}
