import React , {useState , useEffect} from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import { withTranslation } from "react-i18next";
import {connect} from "react-redux";
import {changeLanguage} from "../../../redux/actions";

const  Lang = (props) => {
    const [lang, setLang] = useState("az");
    let newLang = localStorage.getItem('locale')
    const changeLang = (lang) => {
        const { i18n } = props;
        localStorage.setItem("locale", lang);
        i18n.changeLanguage(lang);
        setLang(lang);
        // for creating new request
        setTimeout(()=>{
            props.changeLanguage(lang);
        } , 10)
        window.location.reload()
    };

    useEffect(() => {
        setLang(
            localStorage.getItem("locale") ? localStorage.getItem("locale") : "az"
        );
    } , [lang])

    return (
        <div className="lang ml-2">
            <Dropdown>
                <Dropdown.Toggle className='text-white'  variant='text' id="dropdown-basic">
                    <p>{newLang === 'az' ? 'AZE' : newLang === 'ru' ? 'RUS' : 'ENG' }</p>
                </Dropdown.Toggle>
                {/*<Dropdown.Menu>*/}
                {/*    { newLang !== 'az' &&*/}
                {/*    <Dropdown.Item   onClick={()=>{changeLang('az')} } className={'animated fadeIn'} ><p  className="def-lang">AZE</p></Dropdown.Item>*/}
                {/*    }*/}
                {/*    { newLang !== 'ru' &&*/}
                {/*    <Dropdown.Item  onClick={()=>{changeLang('ru')} } className={'animated fadeIn'} ><p  className="def-lang">RUS</p></Dropdown.Item>*/}
                {/*    }*/}
                {/*    { newLang !== 'en' &&*/}
                {/*    <Dropdown.Item  onClick={()=>{changeLang('en')} } className={'animated fadeIn'} ><p  className="def-lang">ENG</p></Dropdown.Item>*/}
                {/*    }*/}
                {/*</Dropdown.Menu>*/}

                <Dropdown.Menu>
                    { newLang !== 'az' &&
                    <Dropdown.Item onClick={()=>{changeLang('az')} }  className={'animated fadeIn'} ><p  className="def-lang">AZE</p></Dropdown.Item>
                    }
                    {/*{ newLang !== 'ru' &&*/}
                    {/*<Dropdown.Item className={'animated fadeIn'} ><p  className="def-lang">RUS</p></Dropdown.Item>*/}
                    {/*}*/}
                    { newLang !== 'en' &&
                    <Dropdown.Item onClick={()=>{changeLang('en')} } className={'animated fadeIn'} ><p  className="def-lang">ENG</p></Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default connect(null, { changeLanguage })(withTranslation()(Lang));

