import React from 'react';
import Image from "../../../assets/img/Images";
import {connect} from "react-redux";
import {toggleMenu} from "../../../redux/actions";
import {Link} from "react-router-dom";
import Lang from "../../Elements/Lang/Lang";
import {setNumber} from "../../../redux/actions";

function Header(props) {
     const gotoHome = () => {
         setNumber(0)
     }
    const {toggleMenu , setNumber} = props;
    return (
        <div className="container-fluid">
            <div className="row align-items-center header header-fixed m-0" >
                <div className="col-6">
                    <div onClick={() => {gotoHome()}}>
                        <Link to="/">
                            <img src={Image.Logo} alt="" className="logo-main"/>
                        </Link>
                    </div>
                </div>
                <div className="col-6 d-flex justify-content-end align-items-center pt-0">
                    <Lang/>
                    <div className="ml-lg-5 cursor-pointer ml-md-4 ml-sm-3 ml-3">
                        {props.toggleReducer ?
                            <img onClick={toggleMenu} src={Image.MenuClose} alt=""/> :
                            <img onClick={toggleMenu} src={Image.MenuOpen} alt=""/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({toggleReducer}) => {
    return {toggleReducer}
}
export default connect(mapStateToProps , {toggleMenu, setNumber})(Header);
