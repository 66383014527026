export const en = {
    home: {
        text_header: 'HOME PAGE',
        slogan: 'The point where you stand is the center of the world!',
        mission: 'Mission : ',
        mission_text: ' We are building doors that open to the digital world. Our partnership with the government continues with innovative ideas and technologies to keep your personal information safe.',
        vision: 'Vision : ',
        vision_text: ' To constantly increase our productivity and quality in all directions with the strength of our labor and production in the field of ICT.',
        strategy: 'Strategy : ',
        strategy_text: ' It is our constant strategy to support the nationalization of investment in the field of ICT, to contribute to the creation and improvement of nationalInternet resources.',
        story: 'VİEW OUR STORY',

        page_transition: {
            name: 'HOME PAGE',
            next: 'WHO ARE WE? ABOUTOURCOMPANY',
        }
    },
    about: {
        header: 'WHO ARE WE?',
        text_header: 'ABOUT OUR COMPANY',
        text: 'The Virtual Azerbaijan group of companies, which started its activities in 2014, is currently one of the companies operating in the field of information technology in Azerbaijan. The activities of our company serve to automate the work carried out in the field of \n' +
            'business and government at various enterprises in Azerbaijan, as well as to increase productivity, efficiency, and optimization. Effective use of information technology to achieve this goal saves time and improvesworkflow efficiency. In a short time, we have signed a number of successful projects in this area and implemented a number of state projects in our country.',

        page_transition: {
            name: 'ABOUT OUR COMPANY',
            next: 'OUR PROFESSİONAL SERVİCES',
        },
        partners: 'OUR PARTNERS, WHICH ARE ALWAYS WITH US',

        company_structure: 'COMPANY STRUCTURE',
    },
    services: {
        header: 'PROFESSİONAL',
        title: 'SERVICES',
        description: 'The activity of our company serves to automate the work done in business and public administration in various enterprises of Azerbaijan and to increase productivity, efficiency, as well as optimization. There are dozens of devices with logos of our clients available on the market. Under any logo, our devices retain high quality and easiness of use, which leads to end customer satisfaction, while our clients as well as hardware and smart device manufacturers and suppliers become successful on the market. ',
        page_transition: {
            name: 'SERVICES',
            next: 'WHY CHOOSE US ?',
        },
    },
    choose_us: {
        title: 'WHY CHOOSE US ?',
        desc: 'We are aware that no innovation will be effective as long as it is productive, reliable, easy to use and compatible with existing assets. We try to ensure the application of modern technologies. With our deep technological and market expertise in the industry, you can avoid most of the investment and technological risks when manufacturing new home automation products.',
        customers: 'THANKS FROM CUSTOMERS',
        page_transition: {
            name: 'WHY CHOOSE US ?',
            next: 'PORTFOLIO',
        },
        services: {
            service1: 'Software',
            desc1: 'The most modern and perfect form of information processing is applied.',
            service2: 'Equipment assistance',
            desc2: 'It is provided with all the technical means that make your work comfortable.',
            service3: 'Professional Planning',
            desc3: 'Every step of the project is planned by our professionals.',
            service4: 'Creative design',
            desc4: 'Creative UI & UX works are prepared by our professional staff.',
            service5: 'Perfect Result',
            desc5: 'As a result of the successful work of our team, the task is delivered in the most perfect way',
            service6: 'Technical Support',
            desc6: 'We provide our customers either with standard or extended technical support.',
        }
    },
    portfolio: {
        title: 'OUR LATEST WORK FROM OUR PORTFOLIO',
        catalogAll: 'All',
        page_transition: {
            name: 'PORTFOLIO',
            next: 'OUR TEAM',
        },
    },

    team: {
        title: 'OUR TEAM',
        catalogAll: 'All',
        role: 'CEO & Founder of Virtual Azerbaijan Group of Companies',
        stat: 'Slogan',
        slogan: 'The success of our Entrepreneurs is an indicator of our developed society.\n' +
            'For this reason, we strive for the success of society as much as our individual success.',
        page_transition: {
            name: 'OUR TEAM',
            next: 'VACANCY',
        },
    },
    vacancy: {
        title: 'VACANCY',
        catalogAll: 'All',
        page_transition: {
            name: 'VACANCY',
            next: 'MEDİA',
        },
    },
    fullVacancy: {
        back: 'BACK',
        jobDesc: 'Job description:',
        requirements: 'Requirements:' ,
        postDate: 'Post history:',
        lastDate: 'Last date:',
        salary: 'Suggested salary',
        exp: 'Experience',
        sex: 'Gender',
        specialty: 'Specialty',
        level: 'Level',
    },
    apply: {
        apply: 'Apply',
        success: 'Your message has been sent successfully',
        validation: 'The cell must be filled!',
        cv: 'Download CV',
        name: 'Name surname',
        email: 'Email',
        tel: 'Telephone number',
        message: 'Your message',
        send: 'SEND A MESSAGE',

    },
    media: {
        title: 'MEDIA',
        page_transition: {
            name: 'MEDIA',
            next: 'CONTACT US',
        },
    },
    contact: {
        header: 'CONTACT US',
        text_header: 'The activities of our company serve to automate the work carriedout in the field of business and government at various enterprises in Azerbaijan, as well as to increase productivity, efficiency, and optimization.\n' +
            ' Send us a message via this website or use the contact information below',
        our_address: 'Our address :',
        address: 'Azerbaijan Baku. Time Business Center. 6th Floor',
        mobile: 'Contact by phone :',
        email: 'Contact by email :',

        page_transition: {
            name: 'CONTACT US',
            next: 'HOME PAGE',
        },
        contact_input: {
            contact_us: 'Get in touch',
            name: 'Name surname father\'s name',
            email: 'Email',
            message: 'Your message',
            send: 'Send'
        },
        validation: {
            success_message: 'Your message has been sent successfully',
            error_message: 'The cell must be filled',
        }
    },
    menu: {
        about: 'ABOUT OUR COMPANY',
        services: 'OUR SERVICES',
        choose: 'WHY CHOOSE US?',
        portfolio: 'PORTFOLİO',
        team: 'OUR TEAM',
        vacancy: 'VACANCIES',
        media: 'MEDİA',
        contact: 'CONTACT US',
    },
}
