import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import Slider from "react-slick";
import {connect} from "react-redux";
import {getCatalogs, getVacancy} from "../../../redux/actions";
import moment from "moment"
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

function Vacancy(props) {
    const {t} = useTranslation()
    const [vacancyList , setVacancy] = useState([])
    let [trigger, setTrigger] = useState(0);
    useEffect(()=>{
        setVacancy(props.vacancy)
    },  [props , trigger])
    const pageslider = useRef();
    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: false,
        speed: 1500,
        autoplaySpeed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows : false,
        rows: 2,
        centerPadding: "-15px",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2 ,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 561,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    speed: 700,
                    autoplaySpeed: 2000,
                }
            },
        ]
    };
    const next = () => {
        pageslider.current.slickNext();
    }
    const previous = () => {
        pageslider.current.slickPrev();
    }
    const filterTeam = (id) => {
        console.log(id)
        if (id) {
            let filtered = props.vacancy.filter((f) => {
                return f.catalog_id === id
            })
            console.log(filtered)
            setVacancy(filtered)
        }
        else {
            console.log('salam aee')
            setTrigger(++trigger)
        }
    }
    const {getCatalogs, catalogs , getVacancy  , vacancy} = props
    
    useEffect(() => {
        let mounted = true
        if(mounted) {
            !catalogs.length && getCatalogs()
            !vacancy.length && getVacancy()
        }
    })
    return (
        <div className="main">
            <Helmet>
                <title>{t('vacancy.title')}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="section new chooseus-content col-lg-2 col-md-6 col-sm-10 col-12 p-0">
                            <div className="section-header d-flex mt-3 ml-1">
                                <p>{t('vacancy.title')}</p>
                            </div>
                        </div>
                        <div className="works-links col-lg-9 col-md-12 col-sm-12 col-12 p-0 d-flex justify-content-lg-end">
                            <ul className="d-sm-flex d-block p-0">
                                <li className="">
                                    <span onClick={()=>{filterTeam(undefined)}} className="line-links">{t('vacancy.catalogAll')}</span>
                                </li>
                                {
                                    catalogs.filter((c) => {
                                        return c.type === 3
                                    }).map((c,i) => (
                                        <li  key={i} className="">
                                            <span onClick={()=>{filterTeam(c.id)}} className="line-links">{c.locale.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                            {
                                vacancyList.length > 6 &&
                                <div className="d-flex customer-arrows justify-content-end">
                                    <div onClick={()=>{previous()}} className="customer-arrow mr-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.696" height="18.696" viewBox="0 0 10.696 18.696"><path d="M1446.011,1743.366l.177-.177-.9-.9,9-9,1.414,1.414-7.823,7.823,8.1,8.1-1.354,1.355Z" transform="translate(-1445.293 -1733.293)" fill="#fff"/></svg>
                                    </div>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.503" height="21.252" viewBox="0 0 16.503 21.252"><path d="M1501.456,1733.874l.8.605-15.707,20.647-.8-.606Z" transform="translate(-1485.748 -1733.874)" fill="#fff"/></svg>
                                    </div>
                                    <div onClick={()=>{next()}} className="customer-arrow ml-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.976" height="18.977" viewBox="0 0 10.976 18.977"><path d="M1533.011,1750.613l8.317-8.226-8.028-7.94,1.452-1.435,9.236,9.135-.919.909.182.18-8.849,8.753Z" transform="translate(-1533.011 -1733.012)" fill="#fff"/></svg>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="vacancy-slider">
                        <div className="row col-lg-11 col-12 vacancy">
                            {vacancyList.length > 5  ?
                                <Slider
                                    ref={slider => (pageslider.current = slider)}
                                    {...settings}>
                                    {
                                        vacancyList?.map((v, i)=>(
                                            <div key={i} className="p-0">
                                                <Link to={`/full-vacancy/${v.id}`}>
                                                    <div className="list
                                                    {/*middle*/}
                                                    ">
                                                        <div className="d-flex align-items-center vacancy-header">
                                                            <div>
                                                                <img src={v.file.file ? v.file.file : Image.Code} alt=""/>
                                                            </div>
                                                            <div>
                                                                <p>{v.locale.title}</p>
                                                                <span>Posted {moment(v?.locale?.created_at).format("DD-MM-YYY")}</span>
                                                            </div>
                                                        </div>
                                                        <div className="vacancy-salary d-flex align-items-center justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <img src={Image.Case} alt=""/>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <p>{v.locale.experience}</p>
                                                                    <div>
                                                                        <img src={Image.Dot} alt=""/>
                                                                    </div>
                                                                    <p>{v.locale.work_schedule}</p>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span>{v.salary} AZN</span>
                                                            </div>
                                                        </div>
                                                        <div className="vacancy-text">
                                                            <p className={'line-clamp line-2'}>
                                                                {v.locale.short_description}
                                                            </p>
                                                        </div>
                                                        {/*<div className={'text-dark'}>*/}
                                                        {/*    {v.locale.requirements}*/}
                                                        {/*</div>*/}
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </Slider>
                                :
                                <>
                                    {
                                        vacancyList?.map((v, i)=>(
                                            <Link key={i} className={'col-xl-4 col-md-6 px-0 col-12'}  to={`/full-vacancy/${v.id}`}>
                                                <div className="list
                                                    {/*middle*/}
                                                    ">
                                                    <div className="d-flex align-items-center vacancy-header">
                                                        <div>
                                                            <img src={v.file.file ? v.file.file : Image.Code} alt=""/>
                                                        </div>
                                                        <div>
                                                            <p>{v?.locale?.title}</p>
                                                            <span>Posted {moment(v?.locale?.created_at).format("DD-MM-YYYY")}</span>
                                                        </div>
                                                    </div>
                                                    <div className="vacancy-salary d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <img src={Image.Case} alt=""/>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <p>{v.locale?.experience}</p>
                                                                <div>
                                                                    <img src={Image.Dot} alt=""/>
                                                                </div>
                                                                <p>{v.locale?.work_schedule}</p>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>{v.salary} AZN</span>
                                                        </div>
                                                    </div>
                                                    <div className="vacancy-text">
                                                        <p className='line-clamp line-2'>
                                                            {v.locale?.short_description}
                                                        </p>
                                                    </div>

                                                </div>
                                            </Link>
                                        ))
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({catalogs , vacancy }) => {
    return {catalogs , vacancy };
}

export default connect(mapStateToProps, { getCatalogs , getVacancy}) (Vacancy);

