import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import {combineReducers} from "redux";
import {
    toggleReducer,
    hoverReducer,
    aboutReducer,
    servicesReducer,
    worksReducer,
    partnersReducer,
    certificatesReducer,
    teamsReducer,
    newsPageReducer,
    catalogsReducer,
    menuReducer,
    chooseReducer, reviewsReducer, newsReducer, vacancyReducer, vacancyOneReducer
} from "./reducers";

const rootReducer = combineReducers({
    toggleReducer,
    hover: hoverReducer,
    about: aboutReducer,
    partners: partnersReducer,
    certificates: certificatesReducer,
    services: servicesReducer,
    works: worksReducer,
    teams: teamsReducer,
    newsPage: newsPageReducer,
    news: newsReducer,
    catalogs: catalogsReducer,
    choose: chooseReducer,
    reviews: reviewsReducer,
    vacancy: vacancyReducer,
    vac: vacancyOneReducer,
    menu: menuReducer,
});

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export default store;
