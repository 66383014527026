import React from 'react';
import ContactForm from "../../Elements/ContactForm/ContactForm";
// import GoogleM from "../../Elements/GoogleMap/GoogleM";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function Contact(props) {
    const {t} = useTranslation();
    // const [map] = useState({
    //     lat: 40.3789033,
    //     lng: 49.8588277,
    // })

    return (
        <div className="main">
            <Helmet>
                <title>{'Bizimlə əlaqə'}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="chooseus-content new section col-lg-4 col-md-11 col-sm-11 col-11">
                            <div className="section-header d-flex">
                                <p>{t('contact.header')}</p>
                            </div>
                            <div className="contact">
                                <span>{t('contact.text_header')}</span>
                                <div className="mt-3">
                                    <span>{t('contact.our_address')}</span>
                                    <br/>
                                    <span>{t('contact.address')}</span>
                                </div>
                                <div className="mt-3">
                                    <span>{t('contact.mobile')}</span>
                                    <br/>
                                    <span>+ (994 77) 313-63-13</span>
                                </div>
                                <div className="mt-3">
                                    <span>{t('contact.email')}</span>
                                    <br/>
                                    <p className={'text-white'}>info@vac.az</p>
                                </div>
                            </div>
                            {/*<div className="map-google">*/}
                            {/*    <img src={Image.MapGoogle} alt=""/>*/}
                            {/*</div>*/}

                                <div className="map-google">
                                    {/* <GoogleM obj={map}/> */}
                                </div>
                        </div>

                        <div className="col-lg-5 col-12 form">
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Contact;
