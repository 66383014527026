import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import './../../../assets/css/_hexogen.scss'
import Slider from "react-slick";
import {connect} from "react-redux";
import {getTeams, getCatalogs} from "../../../redux/actions";
import Lightbox from "react-image-lightbox";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

function Leadership(props) {
    // const [id, setId] = useState(undefined);
    const {t} = useTranslation()
    let [trigger, setTrigger] = useState(0);
    const [our_teams, setOurTeams] = useState([]);
    const [current, setCurrent] = useState('')
    const handleCloseModal = (e) => {
        e && e.preventDefault();
        setCurrent('')
    }
    const handleClickImage = (e, image) => {
        e && e.preventDefault();
        setCurrent(image)
    }
    useEffect(()=>{
        console.log(props.teams);
        if(props.teams.length){
            let new_teams = [];
            for(let i = 0; i <= Math.floor(props.teams.length /10); i++){
                new_teams.push([]);
            }
            props.teams.forEach((item, index) => {
                let a = Math.floor(index/10);
                new_teams[a].push({...item});
            });
            setOurTeams(new_teams);
        }
    },  [props.render, props.teams , trigger])
    const pageslider = useRef();
    const settings = {
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const next = () => {
        pageslider.current.slickNext();
    }
    const previous = () => {
        pageslider.current.slickPrev();
    }

    const {getTeams, teams } = props
    const {getCatalogs, catalogs} = props
    useEffect(() => {
        let mounted = true
        if(mounted) {
            if(teams.length === 0) {
                getTeams()
            }
            if ( catalogs.length === 0) {
                getCatalogs()
            }
        }
    }, [])



    const filterTeam = (id) =>{
       if (id) {
           setOurTeams([])
           let filteredTeam = props.teams.filter((f)=>{
               return f.catalog_id === id
           })
           if(filteredTeam.length){
               let new_teams = [];
               for(let i = 0; i <= Math.floor(filteredTeam.length /10); i++){
                   new_teams.push([]);
               }
               filteredTeam.forEach((item, index) => {
                   let a = Math.floor(index/10);
                   new_teams[a].push({...item});
               });
               setOurTeams(new_teams);
           }
       }
       else{
           setTrigger(++trigger)
       }
    }

    return (
        <div className="main">
            <Helmet>
                <title>{t('team.title')}</title>
            </Helmet>
            <div className="position-relative">
                <div className="container-fluid row">
                    <div className="col-sm-6 col-12">
                        <div className="section new chooseus-content p-0">
                            <div className="section-header m-0">
                                <p>{t('team.title')}</p>
                            </div>
                        </div>
                        <div className="hexagon-leadership">
                            <section className="hexagon-gallery-leadership d-flex">
                                <div className="hex-main">
                                    <Link>
                                        <div className="hex">
                                            <img src={Image.ElvinAbbasov} onClick={e => handleClickImage(e, Image.ElvinAbbasov)} alt="some">
                                            </img>
                                        </div>
                                    </Link>
                                    <div className="leadership-text">
                                        <div>Elvin Abbasov</div>
                                        <span>{t('team.role')}</span>
                                        <p>
                                            <span>{t('team.stat')}:</span>{t('team.slogan')}</p>
                                        <div className="leadership-social-icon">
                                            <ul className="d-flex p-0 align-items-center">
                                                <a href={"https://www.facebook.com/shovalye"} target={"_blank"}><li><img src={Image.Facebook} alt=""/></li></a>
                                                <a href={"https://twitter.com/interlandaz"} target={"_blank"}><li><img src={Image.Twitter} alt=""/></li></a>
                                                <a href={"https://www.linkedin.com/in/elvin-abbasov/"} target={"_blank"}><li><img src={Image.Linkedin} alt=""/></li></a>
                                                <a href={"https://az.wikipedia.org/wiki/Elvin_Abbasov_(i%C5%9F_adam%C4%B1)"} target={"_blank"}><li className="last-icon"><img src={Image.Wikipedia} alt=""/></li></a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="leadership-links">
                            <ul>
                                <li>
                                    <span onClick={ ()  =>{filterTeam(undefined) }} className="line-links">{t('team.catalogAll')}</span>
                                </li>
                                {
                                    catalogs.filter((c) => {
                                        return c.type === 1
                                    }).map((c, i) => (
                                        <li>
                                            <span onClick={ ()  =>{filterTeam(c.id) }} className="line-links">{c.locale.name}</span>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 team" >
                        <div className="leadership-slider">
                            <div className="hexagon-staff ">
                                <section className="hexagon-gallery-staff ">
                                    {
                                        our_teams.length > 1 ?
                                            <div>
                                                <Slider
                                                    ref={slider => (pageslider.current = slider)}
                                                    {...settings}
                                                >
                                                    {our_teams.map(team => {
                                                        return (

                                                    <div>
                                                        {
                                                            team.map((item,i)=> {
                                                                return (
                                                                <div key={i} className="animated zoomIn hex-staff">
                                                                    <div className="hex position-relative">
                                                                        <img src={item.file.file} alt="some">
                                                                        </img>
                                                                        <div className="about-staff">
                                                                            <p>{item.locale?.name}</p>
                                                                            <span dangerouslySetInnerHTML={{ __html:item.locale?.description}} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                        )
                                                    })}
                                                </Slider>
                                            </div> :
                                            <div className="our_team mt-lg-n3 mt-0">
                                                {
                                                    our_teams.length && our_teams[0]
                                                        .map((team, i) => (
                                                            <div key={i} className="hex-staff animated zoomIn">
                                                                <div className="hex position-relative">
                                                                    <img src={team.file.file} alt="some">

                                                                    </img>
                                                                    <div className="about-staff">
                                                                        <p>{team.locale?.name}</p>
                                                                        <span dangerouslySetInnerHTML={{ __html:team.locale?.description}} />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))
                                                }
                                            </div>
                                    }
                                </section>
                                {
                                    our_teams.length > 1 &&
                                    <div className="d-flex leader-arrows justify-content-end">
                                        <div onClick={()=>{previous()}} className="leader-arrow">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.696" height="18.696" viewBox="0 0 10.696 18.696"><path d="M1446.011,1743.366l.177-.177-.9-.9,9-9,1.414,1.414-7.823,7.823,8.1,8.1-1.354,1.355Z" transform="translate(-1445.293 -1733.293)" fill="#fff"/></svg>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.503" height="21.252" viewBox="0 0 16.503 21.252"><path d="M1501.456,1733.874l.8.605-15.707,20.647-.8-.606Z" transform="translate(-1485.748 -1733.874)" fill="#fff"/></svg>
                                        </div>
                                        <div onClick={()=>{next()}}  className="leader-arrow">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="10.976" height="18.977" viewBox="0 0 10.976 18.977"><path d="M1533.011,1750.613l8.317-8.226-8.028-7.94,1.452-1.435,9.236,9.135-.919.909.182.18-8.849,8.753Z" transform="translate(-1533.011 -1733.012)" fill="#fff"/></svg>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {
                    current && <Lightbox mainSrc={current} onCloseRequest={handleCloseModal} />
                }
            </div>
        </div>
    );
}

const mapStateToProps = ({ teams, catalogs }) => {
    return { teams, catalogs };
}


export default connect(mapStateToProps, {getTeams, getCatalogs}) (Leadership);
