import React from 'react';
import Fade from 'react-bootstrap/Fade'
import {connect} from "react-redux";
import {toggleMenu, setMenu, setNumber} from './../../../redux/actions'
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import history from "../../../const/history";
import {useTranslation} from "react-i18next";

function MenuList(props) {
    const {t } = useTranslation();
    const menuItems = [{
        title: '',
        url: "",
    },{
        title: t('menu.about'),
        url: "/about"
    },{
        title: t('menu.services'),
        url: "/services"
    },{
        title: t('menu.choose'),
        url: "/choose-us"
    },{
        title: t('menu.portfolio'),
        url: "/works"
    },{
        title: t('menu.team'),
        url: "/leadership"
    },{
        title: t('menu.vacancy'),
        url: "/vacancy"
    },{
        title: t('menu.media'),
        url: "/news"
    },{
        title: t('menu.contact'),
        url: "/contact"
    }]
    const pagedatas = [
        {
            name: 'ANA SƏHİFƏ',
            next: 'BİZ KİMİK? ŞİRKƏTİMİZ HAQQINDA',
        },
        {
            name: 'BİZ KİMİK? ŞİRKƏTİMİZ HAQQINDA',
            next: 'PROFESSİONAL XİDMƏTLƏRİMİZ',
        },
        {
            name: 'XİDMƏTLƏRİMİZ',
            next: 'BİZİ NİYƏ SEÇMƏLİSİNİZ?',
        },
        {
            name: 'BİZİ NİYƏ SEÇMƏLİSİNİZ?',
            next: 'PORTFOLİO',
        },
        {
            name: 'PORTFOLİO',
            next: 'KOMANDAMIZ',
        },
        {
            name: 'KOMANDAMIZ',
            next: 'VAKANSIYALAR',
        },
        {
            name: 'VAKANSIYALAR',
            next: 'MEDİA',
        },
        {
            name: 'MEDİA',
            next: 'BİZİMLƏ ƏLAQƏ',
        },
        {
            name: 'BİZİMLƏ ƏLAQƏ',
            next: 'ANA SƏHİFƏ',
        },
    ]
    const { toggleMenu, setMenu } = props;

    const handlePageChange = (url, index) => {
        const num = index === undefined ? 0 : index;
        const val = num === 0 ? menuItems.length : num;
        props.setNumber(val);
        toggleMenu();
        setMenu(pagedatas[index]);
        history.push(url, index);
    };
    return (
        <Fade in={props.toggleReducer}>
          <div>
              {props.toggleReducer &&
              <div className="position-relative">
                  <div className="container-fluid animated fadeIn">
                      <div className="menu-bar ">
                          <div className="overlay"></div>
                          <div className="menu">
                              {menuItems.map((item, index) => {
                                  return (
                                      <div key={index} onClick={()=> {handlePageChange(item.url, index)}}>
                                         <Link to="#" className="line-menu">{ item.title }</Link>
                                      </div>
                                  )
                              })}
                          </div>
                          <div className="hex-menu">
                              <img src={Image.Hex} alt=""/>
                          </div>
                          <div className="menu-list-map">
                              <img src={Image.Map} alt=""/>
                          </div>
                          <div className="menu-list-logo">
                              <img src={Image.VirtualAzerbaijan} alt=""/>
                          </div>
                      </div>
                  </div>
              </div>
              }
          </div>
        </Fade>
    );
}

const mapStateToProps = ({toggleReducer, menu}) => {
    return {toggleReducer, menu}
}
export default connect(mapStateToProps , {toggleMenu, setMenu, setNumber})(MenuList);
