export const az = {
    home: {
        text_header: 'ANA SƏHİFƏ',
        slogan: 'Dayandığınız nöqtə dünyanın mərkəzidir!',
        mission: 'Misiya :',
        mission_text: 'Biz sizə rəqəmsəl dünyaya acılan qapılar inşa edirik.\n' +
            '                                    Şəxsi məlumatlarınızın güvənli olması üçün hökumətlə olan iş birliyimiz innovativ ideya və texnologiyalarla davam edir.',
        vision: 'Vision :',
        vision_text: ' İKT sahəsində öz əmək və istehsalatımızın gücü ilə  bütün istiqamətlərdə məhsuldarlığımızı və keyfiyyətimizi daim artırmaq.',
        strategy: 'Strategiya : ',
        strategy_text: ' İKT sahəsində Sərmayənin milləşməsinə dəstək olmaq, Milli internet resurslarının yaranmasına və təkminləşməsinə töhvə vermək dəyişməz strategiyamızdır.',
        story: 'BİZİM TARİXÇƏMİZƏ BAXIN',

        page_transition: {
            name: 'ANA SƏHİFƏ',
            next: 'BİZ KİMİK? ŞİRKƏTİMİZ HAQQINDA',
        }
    },
    about: {
        header: 'BİZ KİMİK?',
        text_header: 'ŞİRKƏTİMİZ HAQQINDA',
        text: '2014-ci ildən fəaliyyətə başlayan "Virtual Azərbaijan Group of Company" şirkəti hazırda Azərbaycanda İT sahəsində fəaliyyət göstərənşirkətlərdən biridir. Şirkətimizin fəaliyyəti Azərbaycanın müxtəlif müəssisələrindəki biznes və dövlət idarəçiliyində görülən işlərin avtomatlaşdırılmasına və məhsuldarlığının, effektivliyinin artırılması, həmçinin optimallaşdırılmasına xidmət edir. Bu hədəfə çatmaq üçün İnformasiya Texnologiyalarından effektiv istifadə etməklə vaxta qənaətvə daha səmərəli iş prosesi əldə edilir. Biz qısa zaman ərzində bu sahədə bir sıra uğurlu layihələrə imza atmışıq və ölkəmizdə bir sıra dövlət layihələrini icra etmişik.',

        page_transition: {
            name: 'BİZ KİMİK?',
            next: 'PROFESSİONAL XİDMƏTLƏRİMİZ',
        },
        partners: 'DAİM BİZİMLƏ OLAN PARTNYORLARIMIZ',

        company_structure: 'ŞİRKƏTİN STRUKTURU',
    },
    services: {
        header: 'PROFESSİONAL',
        title: 'XİDMƏTLƏRİMİZ',
        description: 'Şirkətimizin fəaliyyəti Azərbaycanın müxtəlif müəssisələrindəki biznes və dövlət idarəçiliyində görülən işləri avtomatlaşdırmaq və məhsuldarlığının, effektivliyinin artırılması, həmçinin optimallaşdırılmasına xidmət edir.',
        page_transition: {
            name: 'XİDMƏTLƏRİMİZ',
            next: 'BİZİ NİYƏ SEÇMƏLİSİNİZ?',
        },
    },
    choose_us: {
        title: 'BİZİ NİYƏ SEÇMƏLİSİNİZ ?',
        desc: 'Heç bir innovasiya məhsuldar, etibarlı, istifadəsi asan və mövcud aktivlərə uyğun olmadığı müddətcə səmərəliliyin olmayacağının fərqindəyik, Biz layihələrimizlə bağlı gələcəkdə yarana biləcək sualları, ehtiyacları qabaqcadan aşkarlayaraq müasir tələblərə uyğun yenilikçi fikirlər əsasında səmərəliliyi artirmaq, xərcləri azaltmaq və optimal məhsullar təqdim etməyə, eyni zamanda ən müasir texnologiyaların tətbiqini təmin etməyə çalışırıq.',
        customers: 'MÜŞTƏRİLƏRDƏN TƏŞƏKKÜRLƏR',
        page_transition: {
            name: 'BİZİ NİYƏ SEÇMƏLİSİNİZ ?',
            next: 'PORTFOLIO',
        },
        services: {
            service1: 'Proqram təminatı',
            desc1: 'İnformasiya emalının ən müasir və mükəmməl forması tətbiq edilir.',
            service2: 'Avadanlıq təminatı',
            desc2: 'İşinizin rahatlığı olan bütün texniki vasitələrlə təmin edilir.',
            service3: 'Peşəkar Planlama',
            desc3: 'Layihənin hər addımı peşəkar mütəxəssislərimiz tərəfindən planlanır.',
            service4: 'Kreativ dizayn',
            desc4: 'Peşəkar əməkdaşlarımız tərəfindən kreativ UI & UX işləri hazırlanır.',
            service5: 'Mükəmməl Nəticə',
            desc5: 'Komandamızın uğurlu işi nəticəsində tapşırıq ən mükəmməl şəkildə təhvil verilir',
            service6: 'Texniki dəstək',
            desc6: 'Dəstək komandamız hər zaman işlərinizin yolunda getməsini təmin edir.',
        }
    },
    portfolio: {
        title: 'PORTFOLİOMUZDAN SON İŞLƏRİMİZ',
        catalogAll: 'Hamısı',
        page_transition: {
            name: 'PORTFOLIO',
            next: 'KOMANDAMIZ',
        },
    },
    team: {
        title: 'KOMANDAMIZ',
        catalogAll: 'Hamısı',
        role: 'CEO & Founder of Virtual Azerbaijan Group of Companies',
        stat: 'Stat',
        slogan: 'Biz Sahibkarların uğuru, inkişaf etmiş cəmiyyətimizin göstəricisidir.\n' +
            'Bu səbəbdən fərdi uğurmuz qədər cəmiyyətin uğuru üçün də çalışmaqdayıq.',
        page_transition: {
            name: 'PORTFOLIO',
            next: 'KOMANDAMIZ',
        },
    },
    vacancy: {
        title: 'VAKANSİYALAR',
        catalogAll: 'Hamısı',
        page_transition: {
            name: 'VAKANSİYALAR',
            next: 'MEDİA',
        },
    },
    fullVacancy: {
        back: 'GERI',
        jobDesc: 'İşin təsviri:',
        requirements: 'Tələblər:' ,
        postDate: 'Post tarixi:',
        lastDate: 'Son tarix:',
        salary: 'Təklif olunan əmək haqqı',
        exp: 'Təcrübə',
        sex: 'Cins',
        specialty: 'İxtisas',
        level: 'Səviyyə',
    },
    apply: {
        apply: 'Müraciət et',
        success: 'Mesajınız müvəffəqiyətlə göndərildi',
        validation: 'Xana doldurulmalıdır!',
        cv: 'CV Yüklə',
        name: 'Ad soyad',
        email: 'Email',
        tel: 'Telefon nömrəsi',
        message: 'Mesajınız',
        send: 'MESAJ GÖNDƏRİN',

    },
    media: {
        title: 'MEDİA',
        page_transition: {
            name: 'MEDİA',
            next: 'BİZİMLƏ ƏLAQƏ',
        },
    },
    contact: {
        header: 'BİZİMLƏ ƏLAQƏ',
        text_header: 'Şirkətimizin fəaliyyəti Azərbaycanın müxtəlif müəssisələrindəki biznes və dövlət\n' +
            '                                    idarəçiliyində görülən işləri avtomatlaşdırmaq və məhsuldarlığının, effektivliyinin\n' +
            '                                    artırılması, həmçinin optimallaşdırılmasına xidmət edir.',
        our_address: 'Ünvanımız :',
        address: 'Azərbaycan Bakı. Time Biznes Mərkəzi. 6-cı mərtəbə',
        mobile: 'Mobil ilə əlaqə :',
        email: 'E-poçt ilə əlaqə :',

        page_transition: {
            name: 'BİZİMLƏ ƏLAQƏ',
            next: 'ANA SƏHİFƏ',
        },
        contact_input: {
            contact_us: 'Əlaqə saxla',
            name: 'Ad soyad ata adı',
            email: 'Email',
            message: 'Mesajınız',
            send: 'GÖNDƏR',
        },
        validation: {
            success_message: 'Mesajınız müvəffəqiyətlə göndərildi',
            error_message: 'Xana doldurulmalıdır',
        }
    },
    menu: {
        about: 'ŞİRKƏTİMİZ HAQQINDA',
        services: 'XİDMƏTLƏRİMİZ',
        choose: 'BİZİ NİYƏ SEÇMƏLİSİNİZ?',
        portfolio: 'PORTFOLİO',
        team: 'KOMANDAMIZ',
        vacancy: 'VAKANSİYALAR',
        media: 'MEDİA',
        contact: 'BİZİMLƏ ƏLAQƏ',
    },
}
