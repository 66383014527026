import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
import {connect} from "react-redux";
import {setHover, getVacan} from "../../../redux/actions";
import moment from "moment";
import ApplyForm from "./ApplyForm";
import {useTranslation} from "react-i18next";

function FullVacancy(props) {
    const {t } = useTranslation();
    const {setHover, getVacan, vac} = props
    const {id} = props.match.params 
    useEffect( () => {
        getVacan(id)
    }, [id])
    return (
        <div className="main">
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-0 align-items-center">
                            <div className="full-vacancy-content d-sm-flex justify-content-sm-between align-items-center">
                                <Link to="/vacancy">
                                    <div className="d-flex align-items-center">
                                        <img src={Image.BackArrow} alt=""/>
                                        <p>{t('fullVacancy.back')}</p>
                                    </div>
                                </Link>
                                <div className="full-vacancy-header">
                                    {/*<span>DEVELOPERS</span>*/}
                                    <p>{vac?.locale?.title}</p>
                                </div>
                                <div className="full-vacancy-link">
                                    {/*<p className="m-0">{vac.locale?.work_schedule.toLocaleUpperCase(localStorage.getItem('locale'))}</p>*/}
                                </div>
                            </div>
                            <div className="full-vacancy-text" onMouseEnter={() => {
                                setHover(true)
                            }} onMouseLeave={() => {
                                setHover(false)
                            }}>
                                <div>
                                    <span>{t('fullVacancy.jobDesc')}</span>
                                    <p>{vac?.locale?.short_description}</p>
                                </div>
                                <div>
                                    <span>{t('fullVacancy.requirements')}</span>
                                    <span dangerouslySetInnerHTML={{ __html:vac?.locale?.requirements }} />
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-0">
                            <div className="vacancy-date d-flex justify-content-end">
                                <div className="mr-lg-5 mr-md-4 mr-sm-3 mr-3">
                                    <p>{t('fullVacancy.postDate')}</p>
                                    <span>{moment(vac?.locale?.created_at).format("DD-MM-YYYY")}</span>
                                </div>
                                <div>
                                    <p>{t('fullVacancy.lastDate')}</p>
                                    <span>{moment(vac?.expire_date).format("DD-MM-YYYY")}</span>
                                </div>
                            </div>
                            <div className="about-vacancy d-flex">
                                <div>
                                    <span>{t('fullVacancy.salary')}</span>
                                    <p>{vac.salary} AZN</p>
                                </div>
                                <div>
                                    <span>{t('fullVacancy.exp')}</span>
                                    <p>{vac.locale?.experience}</p>
                                </div>
                                <div>
                                    <span>{t('fullVacancy.sex')}</span>
                                    <p>{vac?.gender === 'm' ? 'Man' : vac?.gender === 'w' ? 'Woman' : 'Both'}</p>
                                </div>
                                {/*<div>*/}
                                {/*    <span>Industry</span>*/}
                                {/*    <p>Development</p>*/}
                                {/*</div>*/}
                                <div>
                                    <span>{t('fullVacancy.specialty')}</span>
                                    <p>{vac.locale?.qualification}</p>
                                </div>
                                <div>
                                    <span>{t('fullVacancy.level')}</span>
                                    <p>{vac.locale?.level}</p>
                                </div>
                            </div>
                            <div className="vacancy-form">
                                <ApplyForm id={id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({hover, vac}) => {
    return {hover, vac};
};


export default connect(mapStateToProps, {setHover, getVacan})(FullVacancy);
