import React from 'react';
import {Link} from "react-router-dom";
import Image from "../../../assets/img/Images";
// import Slider from "react-slick";
// import Lightbox from "react-image-lightbox";

function NotFound() {
    return (
        <div className="main">
            <div className="position-relative">
                <div className="container-fluid">
                    <div className="full-vacancy-content d-sm-flex align-items-center">
                        <Link to="/vacancy">
                            <div className="d-flex align-items-center">
                                <img src={Image.BackArrow} alt=""/>
                                <p>GERİ</p>
                            </div>
                        </Link>
                        <div className="full-vacancy-header">
                            <p>Səhifə tapilmadi</p>
                        </div>
                    </div>

                    <div className="not-found">
                        <img src={Image.NotFound} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default (NotFound);
