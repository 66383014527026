import React, {useEffect, useRef, useState} from 'react';
import Image from "../../../assets/img/Images";
import {Link} from "react-router-dom";
import ModalVideo from "react-modal-video";
import Slider from "react-slick";
import {getAbout} from "../../../redux/actions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

function Home(props) {
    const [show , setShow] = useState(false)
    const {t} = useTranslation();
    const home = useRef();
    const [isOpen, setOpen] = useState(false)
    const [videoId, setVideoId] = useState(false)
    const { getAbout, about } = props

    function getId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url && url?.match(regExp);
        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }


    const settings = {
        dots: false,
        infinite: true,
        cssEase: 'linear',
        autoplay: true,
        speed: 500,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows : false,
        rows: 1,
    };


    useEffect(()=>{
        if (Object.keys(about).length === 0 ){
            getAbout();
        }
        setTimeout(()=>{
            setShow(true)
        },500)
    } ,  [t])


    return (

        <div className="main">
            <Helmet>
                <title>{t('home.text_header')}</title>
            </Helmet>
            
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={getId(about[0]?.youtube_url)} onClose={() => setOpen(false)} />

            <div className="position-relative">
                <div className="container-fluid row">
                    <div className="main-section col-lg-6 col-12 p-0">
                        <div className="main-text">
                            <p>{t('home.slogan')}</p>
                        </div>
                        <div>
                            <img src={Image.VirtualAzerbaijan} alt=""/>
                        </div>
                        <div className="main-slider">
                            <Slider
                                ref={slider => (home.current = slider)}
                                {...settings}>
                                <p className={'text'}>
                                    <b>{t('home.mission')}</b>{t('home.mission_text')}</p>
                                <p className={'text'}>
                                    <b>{t('home.vision')}</b>{t('home.vision_text')}</p>
                                <p className={'text'}>
                                    <b>{t('home.strategy')}</b>{t('home.strategy_text')}</p>
                            </Slider>
                        </div>
                        <div className="d-flex main-link align-items-center mt-lg-4 mt-md-3 mt-sm-2 mt-2">
                            {/*<div onClick={()=>{ setOpen(true) }}>*/}
                            {/*   <Link >*/}
                            {/*       <img src={Image.Play} alt=""/>*/}
                            {/*   </Link>*/}
                            {/*</div>*/}
                            <div>
                                <Link to="/">
                                    <img src={Image.Play} alt=""/>
                                </Link>
                            </div>
                            {/* <div>
                                <Link >
                                    <img src={Image.Play} alt=""/>
                                </Link>
                            </div> */}
                            <div className="ml-lg-4 ml-md-3 ml-sm-2 ml-2">
                                <Link to="/about">{t('home.story')}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="animated fadeIn brain">
                            <img className={!show && 'display-none'} src={Image.Brain} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="map">
                    <img src={Image.Map} alt=""/>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = ({ about }) => {
    return  {about } ;
}

export default connect(mapStateToProps, { getAbout })(Home);

