import types from '../types'

export const hoverReducer = (hover = false , action) =>{
    switch (action.type){
        case types.HOVER:
            return true;
        case types.OUT:
            return false;
        default :
            return hover ;
    }
}

export const toggleReducer = (data = false, action) =>{
    switch (action.type){
        case types.TOGGLE:
            return !data;
        default :
            return data;
    }
}


export function aboutReducer(about=[], action) {
    switch (action.type) {
        case types.GET_ABOUT:
            return action.payload;
        case types.GET_ABOUT_ERROR:
            return action.payload.message;
        default:
            return about;
    }
}

export function partnersReducer(partners=[], action) {
    switch (action.type) {
        case types.GET_PARTNERS:
            return action.payload;
        case types.GET_PARTNERS_ERROR:
            return action.payload.message;
        default:
            return partners;
    }
}

export function certificatesReducer(certificates=[], action) {
    switch (action.type) {
        case types.GET_CERTIFICATES:
            return action.payload;
        case types.GET_CERTIFICATES_ERROR:
            return action.payload.message;
        default:
            return certificates;
    }
}

export function servicesReducer(services= [], action) {
    switch (action.type) {
        case types.GET_SERVICES:
            return action.payload;
        case types.GET_SERVICES_ERROR:
            return action.payload.message;
        default:
            return services;
    }
}
export function worksReducer(works= [], action) {
    switch (action.type) {
        case types.GET_WORKS:
            return action.payload;
        case types.GET_WORKS_ERROR:
            return action.payload.message;
        default:
            return works;
    }
}


export function chooseReducer(choose= {}, action) {
    switch (action.type) {
        case types.GET_CHOOSE_US:
            return action.payload;
        case types.GET_CHOOSE_US_ERROR:
            return action.payload.message;
        default:
            return choose;
    }
}

export function reviewsReducer(choose= [], action) {
    switch (action.type) {
        case types.GET_REVIEWS:
            return action.payload;
        case types.GET_REVIEWS_ERROR:
            return action.payload.message;
        default:
            return choose;
    }
}

export function teamsReducer(teams= [], action) {
    switch (action.type) {
        case types.GET_TEAMS:
            return action.payload;
        case types.GET_TEAMS_ERROR:
            return action.payload.message;
        default:
            return teams;
    }
}

export function catalogsReducer(catalogs= [], action) {
    switch (action.type) {
        case types.GET_CATALOGS:
            return action.payload;
        case types.GET_CATALOGS_ERROR:
            return action.payload.message;
        default:
            return catalogs
    }
}


export function newsPageReducer (newsPage = [], action) {
    switch (action.type) {
        case types.GET_NEWS_PAGE:
            return action.payload;
        case types.GET_NEWS_PAGE_ERROR:
            return action.payload.message;
        default:
            return newsPage;
    }
}

export function newsReducer (news = {}, action) {
    switch (action.type) {
        case types.GET_NEWS_ONE:
            return action.payload;
        default:
            return news;
    }
}

export function vacancyReducer (newsPage = [], action) {
    switch (action.type) {
        case types.GET_VACANCY:
            return action.payload;
        case types.GET_VACANCY_ERROR:
            return action.payload.message;
        default:
            return newsPage;
    }
}

export function vacancyOneReducer (newsPage = {}, action) {
    switch (action.type) {
        case types.GET_VACANCY_ONE:
            return action.payload;
        case types.GET_VACANCY_ONE_ERROR:
            return action.payload.message;
        default:
            return newsPage;
    }
}

export function menuReducer (data = {}, action) {
    switch (action.type) {
        case types.SET_MENU_ITEM:
            return { ...data, ...action.payload };
        case types.SET_PAGE_NUMBER:
            return { ...data, pageNumber: action.payload };
        default:
            return data;
    }
}
