import React from 'react';

function Structure(props) {
    return (
        <div className="main">
            <div className="position-relative">
                <div className="container-fluid row">
                    <div className="col-sm-6 col-12">
                        <div className="section new chooseus-content p-0">
                            <div className="section-header m-0">
                                <p>ŞİRKƏTİN <strong>STRUKTURU</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Structure;
